import _ from "underscore";

const Products = [
  {
    productName: "EcoVISE",
    desc: "0,75kW ze zmienną wydajniością (variable speed)",
    productId: 1,
    category: ["Pompy"],
    image: {
      xs: "/pahlen-ecovise-96x96.jpg",
      sm: "/pahlen-ecovise-120x120.jpg",
      md: "/pahlen-ecovise-300x300.jpg",
      lg: "/pahlen-ecovise-800x800.jpg",
      xl: "/pahlen-ecovise.jpg",
    },
  },
  {
    productName: "WaterVISE",
    desc: "0,55kW / 0,75kW / 1,1kW",
    productId: 2,
    category: ["Pompy"],
    image: {
      xs: "/pahlen-watervise-96x96.jpg",
      sm: "/pahlen-watervise-120x120.jpg",
      md: "/pahlen-watervise-300x300.jpg",
      lg: "/pahlen-watervise-800x800.jpg",
      xl: "/pahlen-watervise.jpg",
    },
  },
  {
    productName: "P2000",
    desc: "1,5kW / 2,2kW / 4kW",
    productId: 3,
    category: ["Pompy"],
    image: {
      xs: "/pahlen-p2000-96x96.jpg",
      sm: "/pahlen-p2000-120x120.jpg",
      md: "/pahlen-p2000-300x300.jpg",
      lg: "/pahlen-p2000-800x800.jpg",
      xl: "/pahlen-p2000.jpg",
    },
  },
  {
    productName: "Jet Swim MOTION",
    desc: "",
    productId: 4,
    category: ["Atrakcje basenowe"],
    image: {
      xs: "/pahlen-jet-swim-motion-96x96.jpg",
      sm: "/pahlen-jet-swim-motion-120x120.jpg",
      md: "/pahlen-jet-swim-motion-300x300.jpg",
      lg: "/pahlen-jet-swim-motion-800x800.jpg",
      xl: "/pahlen-jet-swim-motion.jpg",
    },
  },
  {
    productName: "Jet Swim 2000",
    desc: "",
    productId: 5,
    category: ["Atrakcje basenowe"],
    image: {
      xs: "/pahlen-jet-swim-2000-96x96.jpg",
      sm: "/pahlen-jet-swim-2000-120x120.jpg",
      md: "/pahlen-jet-swim-2000-300x300.jpg",
      lg: "/pahlen-jet-swim-2000-800x800.jpg",
      xl: "/pahlen-jet-swim-2000.jpg",
    },
  },
  {
    productName: "Jet Swim ATHLETE",
    desc: "",
    productId: 6,
    category: ["Atrakcje basenowe"],
    image: {
      xs: "/pahlen-jet-swim-athlete-96x96.jpg",
      sm: "/pahlen-jet-swim-athlete-120x120.jpg",
      md: "/pahlen-jet-swim-athlete-300x300.jpg",
      lg: "/pahlen-jet-swim-athlete-800x800.jpg",
      xl: "/pahlen-jet-swim-athlete.jpg",
    },
  },
  {
    productName: "HMS system hydro-masażu do basenu",
    desc: "",
    productId: 7,
    category: ["Atrakcje basenowe"],
    image: {
      xs: "/pahlen-hms-96x96.jpg",
      sm: "/pahlen-hms-120x120.jpg",
      md: "/pahlen-hms-300x300.jpg",
      lg: "/pahlen-hms-800x800.jpg",
      xl: "/pahlen-hms.jpg",
    },
  },
  {
    productName: "Lampy AP, VS, Spot lights",
    desc: "",
    productId: 8,
    category: ["Wyposażenie niecki Premium (inox EN1.4404 / AISI 316L)"],
    image: {
      xs: "/pahlen-led-96x96.jpg",
      sm: "/pahlen-led-120x120.jpg",
      md: "/pahlen-led-300x300.jpg",
      lg: "/pahlen-led-800x800.jpg",
      xl: "/pahlen-led.jpg",
    },
  },
  {
    productName: "Dysze ścienne",
    desc: "CLASSIC design i MARINE design",
    productId: 9,
    category: ["Wyposażenie niecki Premium (inox EN1.4404 / AISI 316L)"],
    image: {
      xs: "/pahlen-XXX-96x96.jpg",
      sm: "/pahlen-XXX-120x120.jpg",
      md: "/pahlen-XXX-300x300.jpg",
      lg: "/pahlen-XXX-800x800.jpg",
      xl: "/pahlen-inlet-1.jpg",
    },
  },
  {
    productName: "Dysza denna",
    desc: "z regulacja 126120 (basen beton & liner)",
    productId: 10,
    category: ["Wyposażenie niecki Premium (inox EN1.4404 / AISI 316L)"],
    image: {
      xs: "/pahlen-XXX-96x96.jpg",
      sm: "/pahlen-XXX-120x120.jpg",
      md: "/pahlen-XXX-300x300.jpg",
      lg: "/pahlen-XXX-800x800.jpg",
      xl: "/pahlen-inlet-anchoring.jpg",
    },
  },
  {
    productName: "Dysze z kotwami",
    desc: "(anchor/Anclaje): ścienna 126102 oraz denna z regulacją 126110 (beton i płytki)",
    productId: 11,
    category: ["Wyposażenie niecki Premium (inox EN1.4404 / AISI 316L)"],
    image: {
      xs: "/pahlen-XXX-96x96.jpg",
      sm: "/pahlen-XXX-120x120.jpg",
      md: "/pahlen-XXX-300x300.jpg",
      lg: "/pahlen-XXX-800x800.jpg",
      xl: "/pahlen-bottom-inlet.jpg",
    },
  },
  {
    productName: "Skimmer: Wide Mouth 385",
    desc: "skimmer CLASSIC design / skimmer MARINE design",
    productId: 12,
    category: ["Wyposażenie niecki Premium (inox EN1.4404 / AISI 316L)"],
    image: {
      xs: "/pahlen-XXX-96x96.jpg",
      sm: "/pahlen-XXX-120x120.jpg",
      md: "/pahlen-XXX-300x300.jpg",
      lg: "/pahlen-XXX-800x800.jpg",
      xl: "/pahlen-skimmer-classic-wide.jpg",
    },
  },
  {
    productName: "Outlet/spust",
    desc: "denny 125936 lub ścienny 125938",
    productId: 13,
    category: ["Wyposażenie niecki Premium (inox EN1.4404 / AISI 316L)"],
    image: {
      xs: "/pahlen-XXX-96x96.jpg",
      sm: "/pahlen-XXX-120x120.jpg",
      md: "/pahlen-XXX-300x300.jpg",
      lg: "/pahlen-XXX-800x800.jpg",
      xl: "/pahlen-125936.jpg",
    },
  },
  {
    productName: "Maxi-Flo Titanium",
    desc: "z flanszami 75kW / 200kW",
    productId: 14,
    category: ["Wymienniki ciepła"],
    image: {
      xs: "/pahlen-MAXI-FLO-TITANIUM-75-200kW-96x96.jpg",
      sm: "/pahlen-MAXI-FLO-TITANIUM-75-200kW-120x120.jpg",
      md: "/pahlen-MAXI-FLO-TITANIUM-75-200kW-300x300.jpg",
      lg: "/pahlen-MAXI-FLO-TITANIUM-75-200kW-800x800.jpg",
      xl: "/pahlen-MAXI-FLO-TITANIUM-75-200kW.jpg",
    },
  },
  {
    productName: "Hi-Flow Titanium",
    desc: "28kW / 40kW / 75kW",
    productId: 15,
    category: ["Wymienniki ciepła"],
    image: {
      xs: "/pahlen-XXX-96x96.jpg",
      sm: "/pahlen-XXX-120x120.jpg",
      md: "/pahlen-XXX-300x300.jpg",
      lg: "/pahlen-XXX-800x800.jpg",
      xl: "/pahlen-hiflow-1.jpg",
    },
  },
  {
    productName: "Hi-Flow",
    desc: "13kW / 28kW / 40kW / 75kW",
    productId: 16,
    category: ["Wymienniki ciepła"],
    image: {
      xs: "/pahlen-XXX-96x96.jpg",
      sm: "/pahlen-XXX-120x120.jpg",
      md: "/pahlen-XXX-300x300.jpg",
      lg: "/pahlen-XXX-800x800.jpg",
      xl: "/pahlen-hiflow-2.jpg",
    },
  },
  {
    productName: "Hi-Temp Titanium",
    desc: "40kW / 75kW",
    productId: 17,
    category: ["Wymienniki ciepła"],
    image: {
      xs: "/pahlen-XXX-96x96.jpg",
      sm: "/pahlen-XXX-120x120.jpg",
      md: "/pahlen-XXX-300x300.jpg",
      lg: "/pahlen-XXX-800x800.jpg",
      xl: "/pahlen-hitemp-1.jpg",
    },
  },
  {
    productName: "Hi-Temp",
    desc: "40kW / 75kW",
    productId: 18,
    category: ["Wymienniki ciepła"],
    image: {
      xs: "/pahlen-XXX-96x96.jpg",
      sm: "/pahlen-XXX-120x120.jpg",
      md: "/pahlen-XXX-300x300.jpg",
      lg: "/pahlen-XXX-800x800.jpg",
      xl: "/pahlen-hitemp-2.jpg",
    },
  },
  // {
  //   productName: "Aqua MEX Titanium 40kW / 70kW / 100kW",
  //   productId: 19,
  //   category: ["Wymienniki ciepła",
  //   image: {
  //     xs: "/pahlen-XXX-96x96.jpg",
  //     sm: "/pahlen-XXX-120x120.jpg",
  //     md: "/pahlen-XXX-300x300.jpg",
  //     lg: "/pahlen-XXX-800x800.jpg",
  //     xl: "/pahlen-XXX.jpg",
  //   },
  // },
  {
    productName: "Maxi-Flo Titanium",
    desc: "z flanszami 75kW / 200kW",
    productId: 20,
    category: ["Wymienniki ciepła"],
    image: {
      xs: "/pahlen-XXX-96x96.jpg",
      sm: "/pahlen-XXX-120x120.jpg",
      md: "/pahlen-XXX-300x300.jpg",
      lg: "/pahlen-XXX-800x800.jpg",
      xl: "/pahlen-MAXI-FLO-2.jpg",
    },
  },
  {
    productName: "Aqua MEX",
    desc: "40kW / 70kW / 100kW",
    productId: 21,
    category: ["Wymienniki ciepła"],
    image: {
      xs: "/pahlen-XXX-96x96.jpg",
      sm: "/pahlen-XXX-120x120.jpg",
      md: "/pahlen-XXX-300x300.jpg",
      lg: "/pahlen-XXX-800x800.jpg",
      xl: "/pahlen-Aqua-MEX.jpg",
    },
  },
  {
    productName: "Aqua SPA",
    desc: "1,5kW / 2kW",
    productId: 22,
    category: ["El. Grzałki do basenu"],
    image: {
      xs: "/pahlen-XXX-96x96.jpg",
      sm: "/pahlen-XXX-120x120.jpg",
      md: "/pahlen-XXX-300x300.jpg",
      lg: "/pahlen-XXX-800x800.jpg",
      xl: "/pahlen-aqua-spa.jpg",
    },
  },
  {
    productName: "Compact",
    desc: "3-18kW",
    productId: 23,
    category: ["El. Grzałki do basenu"],
    image: {
      xs: "/pahlen-XXX-96x96.jpg",
      sm: "/pahlen-XXX-120x120.jpg",
      md: "/pahlen-XXX-300x300.jpg",
      lg: "/pahlen-XXX-800x800.jpg",
      xl: "/pahlen-compact.jpg",
    },
  },
  {
    productName: "Aqua Comapct",
    desc: "3-18kW",
    productId: 24,
    category: ["El. Grzałki do basenu"],
    image: {
      xs: "/pahlen-XXX-96x96.jpg",
      sm: "/pahlen-XXX-120x120.jpg",
      md: "/pahlen-XXX-300x300.jpg",
      lg: "/pahlen-XXX-800x800.jpg",
      xl: "/pahlen-aqua-compact.jpg",
    },
  },
  {
    productName: "Aqua HL",
    desc: "3-15kW",
    productId: 25,
    category: ["El. Grzałki do basenu"],
    image: {
      xs: "/pahlen-XXX-96x96.jpg",
      sm: "/pahlen-XXX-120x120.jpg",
      md: "/pahlen-XXX-300x300.jpg",
      lg: "/pahlen-XXX-800x800.jpg",
      xl: "/pahlen-aqua-hl.jpg",
    },
  },
  {
    productName: "MidiHeat",
    desc: "18-72kW",
    productId: 26,
    category: ["El. Grzałki do basenu"],
    image: {
      xs: "/pahlen-XXX-96x96.jpg",
      sm: "/pahlen-XXX-120x120.jpg",
      md: "/pahlen-XXX-300x300.jpg",
      lg: "/pahlen-XXX-800x800.jpg",
      xl: "/pahlen-midiheat.jpg",
    },
  },
  {
    productName: "CALIDI XP",
    desc: "10kW / 15kW / 17kW / 28kW / 35kW",
    productId: 27,
    category: ["Pompy ciepła CALIDI XP"],
    image: {
      xs: "/pahlen-XXX-96x96.jpg",
      sm: "/pahlen-XXX-120x120.jpg",
      md: "/pahlen-XXX-300x300.jpg",
      lg: "/pahlen-XXX-800x800.jpg",
      xl: "/pahlen-calidi.jpg",
    },
  },
  {
    productName: "MiniMaster",
    desc: "Baseny prywatne",
    productId: 28,
    category: ["Utrzymanie wody basenowej"],
    image: {
      xs: "/pahlen-XXX-96x96.jpg",
      sm: "/pahlen-XXX-120x120.jpg",
      md: "/pahlen-XXX-300x300.jpg",
      lg: "/pahlen-XXX-800x800.jpg",
      xl: "/pahlen-minimaster.jpg",
    },
  },
  {
    productName: "AutoDos",
    desc: "Baseny komercyjne i publiczne",
    productId: 29,
    category: ["Utrzymanie wody basenowej"],
    image: {
      xs: "/pahlen-XXX-96x96.jpg",
      sm: "/pahlen-XXX-120x120.jpg",
      md: "/pahlen-XXX-300x300.jpg",
      lg: "/pahlen-XXX-800x800.jpg",
      xl: "/pahlen-autodos.jpg",
    },
  },
  {
    productName: "Lampy UV z Titanium",
    desc: "do basenów prywatnych",
    productId: 30,
    category: ["Utrzymanie wody basenowej"],
    image: {
      xs: "/pahlen-XXX-96x96.jpg",
      sm: "/pahlen-XXX-120x120.jpg",
      md: "/pahlen-XXX-300x300.jpg",
      lg: "/pahlen-XXX-800x800.jpg",
      xl: "/pahlen-uv-lamp.jpg",
    },
  },
];

function getProducts() {
  return Products;
}

export const getCategories = () => {
  return _.sortBy(_.uniq(_.flatten(_.pluck(Products, "category"))));
};

export const getCategoryProducts = (category) => {
  return _.filter(Products, (el) => {
    return _.contains(el.category, category);
  });
};

export default getProducts;
