import { useState, useEffect } from "react";
import _ from "underscore";
import axios from "axios";
import { useForm } from "react-hook-form";

import getProducts, {
  getCategories,
  getCategoryProducts,
} from "./data/Products";
import "./index.css";

function App() {
  const categories = getCategories();
  const [step, setStep] = useState(1);
  const [offerProducts, setOfferProducts] = useState([]);
  const [category, setCategory] = useState(0);
  const [categoryProducts, setCategoryProducts] = useState(null);
  const [msg, setMsg] = useState(null);
  const {
    register,
    reset,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    console.log("Kategoria", category);
    const catProd = category ? getCategoryProducts(category) : getProducts();
    setCategoryProducts(catProd);
  }, [category]);

  useEffect(() => {
    console.log("Produkty do oferty", offerProducts);
  }, [offerProducts]);

  const onSubmit = async (data) => {
    console.log(data);
    const result = await axios.post("/api/mail247.php", data);

    if (result.status === 200) {
      setMsg(result.data.msg);
      reset();
    }
  };

  function handleAddProduct(prod) {
    prod = { ...prod, quantity: 1 };
    if (offerProducts.length === 0) {
      setOfferProducts([...offerProducts, prod]);
      return;
    }

    if (
      _.filter(offerProducts, (el) => el.productId === prod.productId).length
    ) {
      offerProducts.forEach((el) => {
        if (el.productId === prod.productId) {
          el.quantity++;
        }
      });
      setOfferProducts([...offerProducts]);
      return;
    }
    setOfferProducts([...offerProducts, prod]);
    console.log(offerProducts);
  }

  function removeOfferProduct(prod) {
    setOfferProducts(
      offerProducts.filter((el) => el.productId !== prod.productId)
    );
  }

  function handlePlusClick(prod) {
    offerProducts.forEach((el) => {
      if (el.productId === prod.productId) {
        el.quantity++;
      }
    });
    setOfferProducts([...offerProducts]);
  }

  function handleMinusClick(prod) {
    offerProducts.forEach((el) => {
      if (el.productId === prod.productId) {
        if (el.quantity > 1) {
          el.quantity--;
        }
      }
    });
    setOfferProducts([...offerProducts]);
  }

  return (
    <div className="pt-12 md:pt-20 min-h-screen">
      <div className="bg-white border-b top-0 shadow-sm fixed w-full backdrop-filter backdrop-blur opacity-80">
        <div className="h-12 md:h-20 flex items-center px-4 container mx-auto">
          <div>
            <img src="pahlen-logo.png" className="h-8 md:h-10" alt="Pahlen" />
          </div>
          <h1 className="text-xl md:text-2xl mx-4 font-bold flex-1">
            Kreator oferty
          </h1>
          {/* <button onClick={() => setStep(2)}>Dalej</button> */}
        </div>
      </div>

      {step === 1 && (
        <div className="container mx-auto p-4">
          <div className="grid grid-cols-2 md:grid-cols-12 gap-3">
            <div className="col-span-7 md:col-span-2 h-100">
              <h1 className="text-xs uppercase font-bold text-gray-600 mb-3 md:ml-3">
                Wybierz kategorię
              </h1>
              <div>
                <select
                  className="md:hidden px-3 py-2 rounded-lg shadow-sm w-full border border-gray-200"
                  onChange={(e) => setCategory(e.target.value)}
                  value={category}
                >
                  <option value="0">Wybierz kategorię</option>
                  {categories.map((el) => {
                    return <option>{el}</option>;
                  })}
                </select>
              </div>

              <div className="hidden md:grid">
                {/* <div
              className={`${
                0 === category
                  ? `bg-gray-100 text-gray-900`
                  : `bg-gray-50 text-gray-600`
              } p-3  hover:bg-gray-100 hover:text-gray-900 cursor-pointer rounded-md shadow-sm mb-3 font-bold transition `}
              onClick={() => setCategory(0)}
            >
              Wszystkie produkty
             */}
                {categories.map((el) => {
                  return (
                    <div
                      className={`${
                        el === category
                          ? `bg-gray-100 text-gray-900 font-bold`
                          : `bg-white text-gray-500`
                      } p-3  hover:bg-gray-100 hover:text-gray-900 cursor-pointer text-sm rounded-md mb-1 transition `}
                      onClick={() => setCategory(el)}
                    >
                      {el}
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="col-span-7">
              <h1 className="text-xs uppercase font-bold text-gray-600 mb-3">
                Lista produktów
              </h1>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                {category === 0 && (
                  <div className="h-40 md:h-96 col-span-2 grid items-center justify-items-center text-center">
                    <div>
                      <svg
                        class="w-12 h-12 mx-auto text-blue-400"
                        viewBox="0 0 512 512"
                      >
                        <title>Information Circle</title>
                        <path
                          d="M248 64C146.39 64 64 146.39 64 248s82.39 184 184 184 184-82.39 184-184S349.61 64 248 64z"
                          fill="none"
                          stroke="currentColor"
                          strokeMiterlimit="10"
                          strokeWidth="32"
                        />
                        <path
                          fill="none"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="32"
                          d="M220 220h32v116"
                        />
                        <path
                          fill="none"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeMiterlimit="10"
                          strokeWidth="32"
                          d="M208 340h88"
                        />
                        <path
                          d="M248 130a26 26 0 1026 26 26 26 0 00-26-26z"
                          fill="currentColor"
                        />
                      </svg>
                      <h1 className="text-xl text-gray-500">
                        Wybierz kategorię
                      </h1>
                    </div>
                  </div>
                )}
                {category !== 0 &&
                  categoryProducts &&
                  categoryProducts.map((prod) => {
                    return (
                      <div
                        key={prod.productId}
                        className="flex items-center px-2 border transition rounded-lg shadow-sm overflow-hidden hover:shadow-md"
                      >
                        <img
                          src={prod.image.xl}
                          width="80"
                          height="80"
                          alt={prod.productName}
                          className="ml-0 m-2 mr-3 rounded-full border border-gray-50 shadow-sm"
                        />
                        <div className="flex-1">
                          <div className="text-xs font-bold text-gray-500">
                            Pahlen
                          </div>
                          <h1 className="font-bold text-gray-800 text-lg">
                            {prod.productName}
                          </h1>
                          <div className="text-sm text-gray-500">
                            {prod.desc}
                          </div>
                        </div>
                        <button
                          className="ml-3 p-1 bg-blue-400 uppercase text-xl w-10 h-10 shadow-sm flex items-center justify-center focus:outline-none font-bold text-white rounded-full hover:bg-blue-800 transition outline-none ring-offset-gray-50"
                          onClick={(e) => handleAddProduct(prod)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-full h-full"
                            viewBox="0 0 512 512"
                          >
                            <title>Add Circle</title>
                            <path
                              d="M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192 192-86 192-192z"
                              fill="none"
                              stroke="currentColor"
                              strokeMiterlimit="10"
                              strokeWidth="32"
                            />
                            <path
                              fill="none"
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="32"
                              d="M256 176v160M336 256H176"
                            />
                          </svg>
                        </button>
                      </div>
                    );
                  })}
              </div>
            </div>
            <div className="col-span-7 md:col-span-3 rounded-md bg-blue-50 p-4">
              <h1 className="text-xs uppercase font-bold text-gray-600 mb-3">
                Zapytanie ofertowe
              </h1>
              {offerProducts.length === 0 && (
                <div className="border-2 border-dashed border-gray-300 rounded-md p-3 text-center h-16 grid items-center justify-items-center text-gray-500 mb-3">
                  Lista jest pusta
                </div>
              )}
              {offerProducts.length !== 0 &&
                offerProducts.map((el, i) => {
                  return (
                    <div
                      key={el.productId}
                      className="flex items-center bg-white px-2 py-1 rounded-md mb-2 shadow-sm"
                    >
                      <img
                        src={el.image.xl}
                        width="36"
                        height="36"
                        alt={el.productName}
                        className="rounded-full mr-1"
                      />
                      <span className="flex-1 text-sm mr-1">
                        {el.productName}
                      </span>
                      <div className="mr-1 text-md font-bold flex flex-row items-center">
                        <button
                          className="outline-none p-1"
                          onClick={(e) => handleMinusClick(el)}
                        >
                          <svg
                            class="w-5 h-5 opacity-40 hover:opacity-100 transition"
                            viewBox="0 0 512 512"
                          >
                            <title>Remove Circle</title>
                            <path
                              d="M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192 192-86 192-192z"
                              fill="none"
                              stroke="currentColor"
                              strokeMiterlimit="10"
                              strokeWidth="32"
                            />
                            <path
                              fill="none"
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="32"
                              d="M336 256H176"
                            />
                          </svg>
                        </button>
                        <span className="mx-2">{el.quantity}</span>
                        <button
                          className="outline-none p-1"
                          onClick={(e) => handlePlusClick(el)}
                        >
                          <svg
                            className="w-5 h-5 opacity-40 hover:opacity-100 transition outline-none"
                            viewBox="0 0 512 512"
                          >
                            <title>Add Circle</title>
                            <path
                              d="M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192 192-86 192-192z"
                              fill="none"
                              stroke="currentColor"
                              strokeMiterlimit="10"
                              strokeWidth="32"
                            />
                            <path
                              fill="none"
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="32"
                              d="M256 176v160M336 256H176"
                            />
                          </svg>
                        </button>
                      </div>
                      <button
                        className="text-xs uppercase font-bold hover:text-red-500 p-1"
                        onClick={(e) => removeOfferProduct(el)}
                      >
                        <svg className="ionicon w-5 h-5" viewBox="0 0 512 512">
                          <title>Close Circle</title>
                          <path
                            d="M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192 192-86 192-192z"
                            fill="none"
                            stroke="currentColor"
                            strokeMiterlimit="10"
                            strokeWidth="32"
                          />
                          <path
                            fill="none"
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="32"
                            d="M320 320L192 192M192 320l128-128"
                          />
                        </svg>
                      </button>
                    </div>
                  );
                })}
              <button
                className="w-full rounded-md bg-blue-400 text-white font-bold px-4 py-3 uppercase hover:bg-blue-900 transition"
                onClick={() => setStep(2)}
              >
                Dalej
              </button>
            </div>
          </div>
        </div>
      )}

      {step === 2 && (
        <div className="container p-4 mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <div className="col-span-7 md:col-span-3 rounded-md bg-blue-50 p-4">
                <h1 className="text-xs uppercase font-bold text-gray-600 mb-3">
                  Produkty
                </h1>
                {offerProducts.length === 0 && (
                  <div className="border-2 border-dashed border-gray-300 rounded-md p-3 text-center h-16 grid items-center justify-items-center text-gray-500 mb-3">
                    Lista jest pusta
                  </div>
                )}
                {offerProducts.length !== 0 &&
                  offerProducts.map((el, i) => {
                    return (
                      <div
                        key={el.productId}
                        className="flex items-center bg-white px-2 py-1 rounded-md mb-2 shadow-sm"
                      >
                        <img
                          src={el.image.xl}
                          width="46"
                          height="46"
                          alt={el.productName}
                          className="rounded-full mr-1"
                        />
                        <span className="flex-1 text-md mr-1">
                          {el.productName}
                        </span>
                        <div className="mr-1 text-md flex flex-row items-center">
                          <span className="mx-2">
                            Ilość: <strong>{el.quantity}</strong>
                          </span>
                        </div>
                      </div>
                    );
                  })}
                {/* <button
                  className="w-auto rounded-md bg-gray-100 text-gray-400 font-bold px-4 py-3 uppercase hover:bg-blue-900 transition"
                  onClick={() => setStep(1)}
                >
                  Popraw
                </button> */}
              </div>
            </div>
            <div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="mt-0">
                  <h1 className="text-xl mb-3">Dane kontaktowe</h1>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Imię i nazwisko (wymagane):
                    </label>
                  </div>
                  <input
                    type="text"
                    {...register("name", {
                      required: true,
                    })}
                    className={
                      errors?.name
                        ? "border-red-900 focus:ring-red-900 focus:border-red-900"
                        : ""
                    }
                  />
                  {errors?.name?.type === "required" && (
                    <p className="block text-sm font-medium text-red-900">
                      To pole jest wymagane
                    </p>
                  )}
                </div>
                <div className="mt-4">
                  <label className="block text-sm font-medium text-gray-700">
                    Telefon (wymagane):
                  </label>
                  <input
                    type="text"
                    {...register("tel", {
                      required: true,
                    })}
                    className={
                      errors?.tel
                        ? "border-red-900 focus:ring-red-900 focus:border-red-900"
                        : ""
                    }
                  />
                  {errors?.tel?.type === "required" && (
                    <p className="block text-sm font-medium text-red-900">
                      To pole jest wymagane
                    </p>
                  )}
                </div>

                <div className="mt-4">
                  <label className="block text-sm font-medium text-gray-700">
                    E-mail (wymagane):
                  </label>
                  <input
                    type="text"
                    {...register("email", {
                      required: true,
                    })}
                    className={
                      errors?.email
                        ? "border-red-900 focus:ring-red-900 focus:border-red-900"
                        : ""
                    }
                  />
                  {errors?.email?.type === "required" && (
                    <p className="block text-sm font-medium text-red-900">
                      To pole jest wymagane
                    </p>
                  )}
                </div>

                <div className="mt-4">
                  <label className="block text-sm font-medium text-gray-700">
                    Dodatkowe uwagi:
                  </label>
                  <textarea {...register("message")}></textarea>
                </div>
                {/* <div className="mt-1 flex items-center">
                <input
                  type="checkbox"
                  id="terms-and-privacy"
                  name="terms-and-privacy"
                  onChange={() => setIsAccepted(!isAccepted)}
                  checked={isAccepted}
                />
                <label
                  htmlFor="terms-and-privacy"
                  className="ml-2 block text-sm text-gray-900"
                >
                  Zapoznałem się i akceptuję postanowienia{" "}
                  <a href="/polityka-prywatnosci.pdf" target="_blank">
                    Polityki prywatności
                  </a>
                </label>
                <input
                  type="hidden"
                  value={appointment}
                  {...register("termin")}
                />
                <input
                  type="hidden"
                  value={doctor?.name}
                  {...register("doctor")}
                /> 
              </div>*/}
                {offerProducts.map((el, index) => {
                  return (
                    <input
                      type="hidden"
                      value={`${el.productName} - Ilość: ${el.quantity}`}
                      {...register(`products[${index}]`)}
                    />
                  );
                })}
                <div className="mt-4 mb-4 flex justify-end">
                  <button
                    type="submit"
                    className={`transition rounded bg-blue-400 font-bold uppercase text-white px-4 py-3 hover:bg-blue-900`}
                  >
                    Wyślij
                  </button>
                </div>
                {msg && (
                  <div
                    class="rounded-md bg-green-300 shadow-sm p-3 text-green-700"
                    role="alert"
                  >
                    {msg}
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
